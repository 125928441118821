import { keyframes } from '@emotion/react';
import { Flex, BigText } from '.';
import { theme } from '../design-system';

export const ScoreIndicator = ({ score }) => {
  const percentage = Math.floor((score / 20) * 100);

  const barFill = keyframes`
  0% { width: 0 }
  100% { width: ${percentage}% }`;

  const ScoreNumbers = () => {
    return (
      <Flex>
        <BigText
          animation={`${fadeIn} 500ms forwards 750ms`}
          style={{
            position: 'absolute',
            top: '40px',
            left: `${percentage}%`,
            transform: 'translateX(-50%)',
            opacity: 0,
            color: '#fff',
            fontSize: '1.5rem',
          }}
        >
          {score}
        </BigText>
        {score < 17 && (
          <BigText
            animation={`${fadeIn} 500ms forwards 1750ms`}
            style={{ position: 'absolute', top: '40px', left: '90%', transform: 'translateX(-50%)', opacity: 0, color: '#fff', fontSize: '1.5rem' }}
          >
            {18}
          </BigText>
        )}
      </Flex>
    );
  };

  return (
    <Flex column style={{ position: 'relative', marginBottom: '2rem', width: '50vw' }}>
      <Flex
        backgroundColor='#fff'
        marginBottom='2rem'
        borderRadius='5px'
        height='35px'
        style={{ position: 'relative', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px' }}
      >
        <Flex height='100%' width={`${percentage}%`} backgroundColor={theme.colors.primary} animation={`${barFill} ease-out 1s forwards`}></Flex>
        <VerticalLine />
      </Flex>
      <ScoreNumbers />
    </Flex>
  );
};

const growLine = keyframes`
0% { height: 0px}
100% { height: 95px }`;

const fadeIn = keyframes`
0% { opacity: 0 }
100% { opacity: 1 }`;

const VerticalLine = () => {
  return (
    <>
      <Flex
        style={{
          position: 'absolute',
          left: '90%',
          transform: 'translateX(-50%)',
          bottom: '135px',
          opacity: 0,
          textAlign: 'center',
          color: theme.colors.primary,
        }}
        animation={`${fadeIn} 1s forwards 1750ms`}
      >{`20/20 Vision`}</Flex>
      <Flex
        animation={`${growLine} 1s forwards 1s`}
        style={{ position: 'absolute', bottom: '35px', left: '90%', width: '2px', backgroundColor: theme.colors.primary }}
      ></Flex>
    </>
  );
};
