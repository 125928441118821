import styled from '@emotion/styled';
import { useState } from 'react';
import { Flex, BigText, Button } from '.';
import { TermsAndConditions } from '../../helpers/TermsAndConditions';
import { PrivacyPolicy } from '../../helpers/PrivacyPolicy';
import { theme } from '../design-system';

const StyledLink = styled.span`
  color: ${theme.colors.primary};
  text-decoration: underline;
`;

export const ConsentCheckbox = ({ checked, setChecked }) => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  return (
    <>
      {(termsOpen || privacyOpen) && (
        <OverlayDialog content={termsOpen ? 'terms' : 'privacy'} closeDialog={() => (termsOpen ? setTermsOpen(false) : setPrivacyOpen(false))} />
      )}
      <Flex marginTop='4rem' alignItems='center'>
        <input type='checkbox' id='consent-checkbox' checked={checked} onChange={() => setChecked(!checked)} style={{ marginRight: '0.5rem' }} />
        <label htmlFor='consent-checkbox' style={{ color: '#fff', height: '14px' }} onClick={(e) => e.preventDefault()}>
          {`I have read, agreed & consent to the `}
          <StyledLink onClick={() => setTermsOpen(true)}>{`terms`}</StyledLink>
          {` and `}
          <StyledLink onClick={() => setPrivacyOpen(true)}>{`privacy policy`}</StyledLink>
        </label>
      </Flex>
    </>
  );
};

const OverlayDialog = ({ content, closeDialog }) => {
  return (
    <Flex centered width='100vw' height='100vh' backgroundColor='rgba(0, 0, 0, 0.8)' style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
      <Flex column alignItems='center' width='70vw' height='80vh' backgroundColor='#02011E' borderRadius='10px' overflow='auto'>
        <Flex centered width='100%' padding='1.5rem' backgroundColor={theme.colors.primary} style={{ position: 'sticky', top: 0 }}>
          <BigText color='#000' fontSize='1.25rem'>
            {content === 'terms' && `Terms & Conditions`}
            {content === 'privacy' && `Privacy Policy`}
          </BigText>
          <BigText color='#000' fontSize='1.5rem' style={{ position: 'absolute', right: '1rem' }} onClick={() => closeDialog()}>{`\u2716`}</BigText>
        </Flex>
        {content === 'terms' && <TermsAndConditions />}
        {content === 'privacy' && <PrivacyPolicy />}
        <Button onClick={() => closeDialog()}>{`CLOSE`}</Button>
      </Flex>
    </Flex>
  );
};
