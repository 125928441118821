import { keyframes } from '@emotion/react';
import { useState } from 'react';
import { BigText, Image, Flex, Button, theme } from '../component-library';
//import { useForm } from '../context/formContext';
//import { useNavigation } from '../context/navigationContext';
import { useScore } from '../context/scoreContext';
import { OpticianEmail, PatientEmail } from '../helpers/Emails';
import SuccessBackground from '../assets/img/success-page-img.webp';

const fadeIn = keyframes`
0% { opacity: 0}
100% { opacity: 1}`;

export const SuccessPage = () => {
  // const [countdownTime, setCountdownTime] = useState(6);
  const [showEmail, setShowEmail] = useState(false);
  // const { returnHome } = useNavigation();
  // const { resetFormData } = useForm();
  const { score } = useScore();

  // useEffect(() => {
  //   const redirectTimer = setTimeout(() => {
  //     resetFormData();
  //     return returnHome();
  //   }, 6000);
  //   const redirectCountdown = setInterval(() => setCountdownTime((time) => time - 1), 1000);

  //   return () => {
  //     clearTimeout(redirectTimer);
  //     clearInterval(redirectCountdown);
  //   };
  // }, [returnHome, resetFormData]);

  return (
    <>
      {showEmail && <OverlayDialog role={showEmail} closeDialog={() => setShowEmail(false)} score={score} />}
      <Image src={SuccessBackground} style={{ height: '100%', width: '100%', position: 'absolute', inset: 0, opacity: 0.1 }} />
      <BigText style={{ opacity: 0 }} animation={`${fadeIn} 1s forwards`}>{`Thanks - now check your email!`}</BigText>
      <Flex marginTop='5rem' width='75vw' justifyContent='space-evenly' style={{ zIndex: 1 }}>
        <Button onClick={() => setShowEmail('optician')} style={{ opacity: 0 }} animation={`${fadeIn} 1s forwards 1s`}>{`Optician Email`}</Button>
        <Button onClick={() => setShowEmail('patient')} style={{ opacity: 0 }} animation={`${fadeIn} 1s forwards 1.5s`}>{`Patient Email`}</Button>
      </Flex>
      {/* <BigText style={{ opacity: 0 }} animation={`${fadeIn} 1s forwards 1s`}>{`Returning back home in ${countdownTime}...`}</BigText> */}
    </>
  );
};

const OverlayDialog = ({ role, closeDialog, score }) => {
  const roleToTitleCase = role.charAt(0).toUpperCase() + role.slice(1);
  return (
    <Flex centered width='100vw' height='100vh' backgroundColor='rgba(0, 0, 0, 0.8)' style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
      <Flex column alignItems='center' width='70vw' height='80vh' backgroundColor='#02011E' borderRadius='10px' overflow='auto'>
        <Flex centered width='100%' padding='1.5rem' backgroundColor={theme.colors.primary} style={{ position: 'sticky', top: 0 }}>
          <BigText color='#000' fontSize='1.25rem'>
            {`${roleToTitleCase} Email`}
          </BigText>
          <BigText color='#000' fontSize='1.5rem' style={{ position: 'absolute', right: '1rem' }} onClick={() => closeDialog()}>{`\u2716`}</BigText>
        </Flex>
        {role === 'optician' && <OpticianEmail score={score} />}
        {role === 'patient' && <PatientEmail score={score} />}
        <Button onClick={() => closeDialog()}>{`CLOSE`}</Button>
      </Flex>
    </Flex>
  );
};
