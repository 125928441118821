import { BigText, Image, Button, Flex, ScoreIndicator } from '../component-library';
import { useNavigation } from '../context/navigationContext';
import { useScore } from '../context/scoreContext';
import { keyframes } from '@emotion/react';
import ResultsImg from '../assets/img/results-img.webp';

const fadeIn = keyframes`
0% { opacity: 0 }
100% { opacity: 1 }
`;

export const Results = () => {
  const { score } = useScore();
  const { nextPage } = useNavigation();
  return (
    <Flex column padding='5rem'>
      <Image src={ResultsImg} alt='woman smiling after workout' style={{ position: 'absolute', top: 0, right: 0 }} />
      <BigText marginBottom='2rem'>{`You have scored:`}</BigText>
      <ScoreIndicator score={score} />
      <BigText
        fontSize='3rem'
        opacity='0'
        marginBottom='2rem'
        fontWeight='bold'
        animation={`${fadeIn} 500ms forwards 750ms`}
      >{`${score} out of 20`}</BigText>

      <BigText marginBottom='1rem' color='#fff' fontSize='2rem' width='75%'>{`On average, a person with 20/20 vision scores 18 out of 20.`}</BigText>
      <BigText
        marginBottom='4rem'
        color='#fff'
        fontSize='2rem'
        width='85%'
      >{`You should aim to have your eyes tested once every 24 months. Click "Get Code" below to get your referral code for an eye test with at your local clinic.`}</BigText>
      <Button onClick={() => nextPage()}>{`Get Code`}</Button>
    </Flex>
  );
};
