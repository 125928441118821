import { Flex, BigText, Button, Image } from '../component-library';
import { useNavigation } from '../context/navigationContext';
import LandingPageLeft from '../assets/img/landing-page-left.webp';
import LandingPageRight from '../assets/img/landing-page-right.webp';

export const LandingPage = () => {
  const { nextPage } = useNavigation();
  return (
    <>
      <Header />
      <Flex
        column
        alignItems='center'
        justifyContent='flex-start'
        style={{ width: '100%', zIndex: 1, position: 'absolute', top: '60vh', transform: 'translateY(-15%)' }}
      >
        <BigText marginBottom='2rem' color='#fff'>{`How strong are my eyes?`}</BigText>
        <Button onClick={() => nextPage()}>{`Find Out`}</Button>
      </Flex>
    </>
  );
};

const Header = () => {
  return (
    <Flex height='60vh' width='100%' style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}>
      <Image src={LandingPageLeft} style={{ position: 'absolute', width: '65vw' }} />
      <Image src={LandingPageRight} style={{ position: 'absolute', right: 0, width: '55vw' }} />
    </Flex>
  );
};
