import { createContext, useCallback, useContext, useState } from 'react';
import { useNavigation } from './navigationContext';

const FormContext = createContext();

export const useForm = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const { nextPage } = useNavigation();
  const [formData, setFormData] = useState({ name: '', email: '', zip: '', insurance: '', dob: { month: '', day: '', year: '' } });

  const updateFormData = useCallback((newData) => {
    return setFormData((existingData) => {
      return { ...existingData, ...newData };
    });
  }, []);

  const resetFormData = useCallback(() => {
    setFormData({ name: '', email: '', zip: '', insurance: '', dob: { month: '', day: '', year: '' } });
  }, []);

  const submitForm = async () => {
    console.log(formData);
    return nextPage();
  };

  return <FormContext.Provider value={{ formData, updateFormData, resetFormData, submitForm }}>{children}</FormContext.Provider>;
};
