import styled from '@emotion/styled';
import { shouldForwardProps } from '../shouldForwardProps';

const StyledImg = ({ ...props }) => {
  const { customPropsObj } = shouldForwardProps(props);

  return { ...customPropsObj };
};

export const Image = styled('img')(StyledImg);
