import { useEffect, useState } from 'react';
import { Flex, Button, Image, theme, BigText } from '../component-library';
import { useNavigation } from '../context/navigationContext';
import { useScore } from '../context/scoreContext';
import { generatePositions } from '../helpers/generatePositions';
import { ReactComponent as LoadingAnimation } from '../assets/img/loading-animation.svg';
import LandoltC from '../assets/img/landolt-c.svg';

const TestLoadingOverlay = ({ imgLoaded, countdown }) => {
  return (
    <Flex
      column
      centered
      paddingTop='8rem'
      width='100vw'
      height='100%'
      backgroundColor='white'
      zIndex='2'
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <LoadingAnimation />
      <BigText fontSize='3rem' style={{ visibility: imgLoaded ? 'visible' : 'hidden', marginTop: '2rem' }}>{`Ready in ${countdown}`}</BigText>
    </Flex>
  );
};

export const TestPage = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [positionsToUse, setPositionsToUse] = useState([]);
  const [activePosition, setActivePosition] = useState(0);
  const [testBeginningCountdown, setTestBeginningCountdown] = useState(3);
  const { nextPage } = useNavigation();
  const { incrementScore, resetScore } = useScore();

  const handleResponse = (response) => {
    if (response && positionsToUse[activePosition].matches) incrementScore();
    if (!response && !positionsToUse[activePosition].matches) incrementScore();
    if (activePosition === 19) return nextPage();
    return setActivePosition((prev) => prev + 1);
  };

  useEffect(() => {
    const positions = generatePositions();
    setPositionsToUse(positions);
    resetScore();
  }, [resetScore]);

  useEffect(() => {
    if (!imgLoaded || testBeginningCountdown === 0) return;
    if (imgLoaded) {
      const countdown = setInterval(() => setTestBeginningCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [imgLoaded, testBeginningCountdown]);

  return (
    <Flex column centered backgroundColor='#fff' width='100%' height='100%'>
      {testBeginningCountdown > 0 && <TestLoadingOverlay imgLoaded={imgLoaded} countdown={testBeginningCountdown} />}
      <Flex margin='5rem 0 7.5rem 0' style={{ position: 'relative' }}>
        <Flex centered width='350px' height='350px'>
          <Image
            src={LandoltC}
            alt='landolt c'
            width={350 - activePosition * 15 || 0}
            style={{ transform: `rotate(${positionsToUse[activePosition]?.landoltC || 0}deg)` }}
            onLoad={() => setImgLoaded(true)}
          />
        </Flex>
        <Flex
          backgroundColor='black'
          borderRadius='50%'
          height='30px'
          width='30px'
          style={{ position: 'absolute', transform: 'translate(-50%, -50%)', ...positionsToUse[activePosition]?.dot }}
        ></Flex>
      </Flex>
      {testBeginningCountdown <= 0 && (
        <Flex width='100vw' justifyContent='center'>
          <Button width='20em' marginRight='10rem' backgroundColor={theme.colors.negativeDark} onClick={() => handleResponse(false)}>{`NO`}</Button>
          <Button width='20em' backgroundColor='#4BB453' onClick={() => handleResponse(true)}>{`YES`}</Button>
        </Flex>
      )}
    </Flex>
  );
};
