import { Flex, Input } from '.';
import styled from '@emotion/styled';
import { useForm } from '../../context/formContext';

const LabelSmall = styled.label`
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: #fff;
`;

export const DOBEntry = () => {
  const {
    updateFormData,
    formData: { dob },
  } = useForm();

  return (
    <Flex column height='6.25em'>
      <p style={{ color: '#fff', marginBottom: 0 }}>Your date of birth</p>
      <Flex width='20em' marginTop='1rem'>
        <Flex>
          <DOBInput
            width='3em'
            label='Month'
            id='dob-month'
            placeholder='MM'
            marginRight='1rem'
            value={dob.month}
            onChange={(e) => updateFormData({ dob: { ...dob, month: e.target.value } })}
          />
          <DOBInput
            width='3em'
            label='Day'
            id='dob-day'
            placeholder='DD'
            marginRight='1rem'
            value={dob.day}
            onChange={(e) => updateFormData({ dob: { ...dob, day: e.target.value } })}
          />
          <DOBInput
            width='5em'
            label='Year'
            id='dob-year'
            placeholder='YYYY'
            value={dob.year}
            onChange={(e) => updateFormData({ dob: { ...dob, year: e.target.value } })}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const DOBInput = ({ label, ...props }) => {
  return (
    <Flex column>
      <LabelSmall htmlFor={props.id}>{label}</LabelSmall>
      <Input width={props.width} {...props} inputMode='numeric' />
    </Flex>
  );
};
