import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GlobalStyle } from './component-library';
import { NavigationProvider } from './context/navigationContext';
import { FormProvider } from './context/formContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <NavigationProvider>
      <FormProvider>
        <App />
      </FormProvider>
    </NavigationProvider>
  </React.StrictMode>
);
