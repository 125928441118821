import { Flex, Image, BigText, Button } from '../component-library';
import { useNavigation } from '../context/navigationContext';
import InstructionsImg from '../assets/img/instructions-img.webp';
import styled from '@emotion/styled';

const StyledOrderedList = styled.ol`
  padding: 1.5rem;
  color: #fff;
  font-size: 1.25rem;
`;

export const PositionInstructions = () => {
  const { nextPage } = useNavigation();
  return (
    <>
      <Image src={InstructionsImg} alt='women laughing' style={{ position: 'absolute', top: 0, left: 0, height: '100vh' }} />
      <Flex column width='60%' marginLeft='15rem'>
        <BigText fontWeight='bold'>{`Let's position you correctly before starting the test.`}</BigText>
        <StyledOrderedList>
          <li>{`Face the screen standing tall.`}</li>
          <li>{`You will see a blue letter C, turned at an angle, and a black dot.`}</li>
          <li>{`Your job is to say whether the opening of the C is pointing in the same direction as the dot.`}</li>
          <li>{`If it is, answer "YES". If it's not, answer "NO".`}</li>
          <li>{`Stand back at full arm's length, so that only your fingertips reach the screen, and get ready.`}</li>
          <li>{`Press "BEGIN" to start the test.`}</li>
        </StyledOrderedList>
        <Button onClick={() => nextPage()}>{`BEGIN`}</Button>
      </Flex>
    </>
  );
};
