import styled from '@emotion/styled';
import { Flex } from './Flex';
import { theme } from '../design-system';
import { shouldForwardProps } from '../shouldForwardProps';

const getPropsByVariant = (variant) => {
  const variants = {
    success: {
      borderBottom: '3px solid #00B092',
      color: '#00B092',
    },
    error: { borderBottom: '3px solid #E25822', color: '#E25822' },
  };

  return variant ? variants[variant] : {};
};

const StyledInput = ({ readOnly }) => {
  return {
    width: '100%',
    minHeight: '2rem',
    padding: '12px',
    color: theme.colors.neutralDark,
    backgroundColor: readOnly ? theme.colors.neutralLight : '#fafafa',
    '&::placeholder': { color: readOnly ? theme.colors.neutralDark : '#aabbc6' },
  };
};

const StyledWrapper = ({ readOnly, variant }) => {
  const propsByVariant = getPropsByVariant(variant);

  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: readOnly ? theme.colors.neutralLight : '#fafafa',
    border: '1px solid #d6e4ec',
    borderRadius: '4px',
    cursor: 'text',
    '&:focus-within': !readOnly && {
      marginBottom: '-2px', //(existing border - new border) to prevent reflow
      borderBottom: `3px solid ${theme.colors.primary}`,
      filter: 'drop-shadow(0px 0px 2px rgba(117, 131, 142, 0.04)) drop-shadow(0px 4px 8px rgba(52, 60, 68, 0.16))',
    },
    '& > input': { outline: 'none', border: 'none', color: propsByVariant.color },
    ...propsByVariant,
  };
};

const InputElement = styled('input')(StyledInput);
const Wrapper = styled('div')(StyledWrapper);
const Label = styled('label')`
  margin-bottom: 0.75rem;
  color: #fff;
`;

export const Input = ({ variant, ...props }) => {
  const { label, name: inputName, readOnly, type, placeholder, value, onChange, autoFocus, onKeyUp, required, inputMode, ...allOtherProps } = props;
  const { customPropsObj } = shouldForwardProps(allOtherProps);

  return (
    <Flex column {...customPropsObj}>
      {label && <Label htmlFor={inputName}>{label}</Label>}
      <Wrapper readOnly={readOnly} variant={variant}>
        <InputElement
          name={inputName}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          onKeyUp={onKeyUp}
          autoFocus={autoFocus}
          required={required}
          inputMode={inputMode}
        />
      </Wrapper>
    </Flex>
  );
};
