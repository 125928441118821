const rotationDegrees = [45, 135, 225, 315];
const cssPositions = [
  { top: '100%', left: '100%' },
  { top: '100%', left: 0 },
  { top: 0, left: 0 },
  { top: 0, left: '100%' },
];
let allPositions = [];

export const generatePositions = () => {
  if (allPositions.length === 20) return randomSort(allPositions);

  const landoltCPosition = Math.floor(Math.random() * 4);
  const dotPosition = Math.floor(Math.random() * 4);
  const matches = landoltCPosition === dotPosition;
  const matchingPositions = allPositions.filter((position) => position.matches);
  const notMatchingPositions = allPositions.filter((position) => !position.matches);
  if (matches && matchingPositions.length < 10)
    allPositions.push({ landoltC: rotationDegrees[landoltCPosition], dot: cssPositions[dotPosition], matches: true });

  if (!matches && notMatchingPositions.length < 10)
    allPositions.push({ landoltC: rotationDegrees[landoltCPosition], dot: cssPositions[dotPosition], matches: false });

  return generatePositions();
};

function isAdjacentSameDegree(arr) {
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i].landoltC === arr[i + 1].landoltC) {
      return true;
    }
  }
  return false;
}

function randomSort(arr) {
  let sorted = false;
  while (!sorted) {
    arr.sort(() => Math.random() - 0.5);
    sorted = !isAdjacentSameDegree(arr);
  }
  return arr;
}
