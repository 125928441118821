import { Input, BigText, Button, Image } from '../component-library';
import { useForm } from '../context/formContext';
import SecondFormImg from '../assets/img/second-form-img.webp';
import styled from '@emotion/styled';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5rem;
`;

export const SecondForm = () => {
  const { formData, updateFormData, submitForm } = useForm();

  const handleFormSubmission = (e) => {
    e.preventDefault();
    return submitForm();
  };

  const fillForm = () => {
    const visionExpoPrefill = { email: 'mberwick@ibisvision.co.uk', insurance: 'VSP', zip: 10001 };
    updateFormData(visionExpoPrefill);
  };

  return (
    <>
      <Image
        src={SecondFormImg}
        alt='man and woman looking at tablet in gym'
        style={{ position: 'absolute', top: 0, right: 0, maxWidth: '450px' }}
        onClick={() => fillForm()}
      />
      <StyledForm onSubmit={handleFormSubmission}>
        <BigText marginBottom='2rem' width='60%'>{`Enter your details below to receive your free code.`}</BigText>
        <Input
          type='email'
          width='20em'
          label='Email'
          value={formData.email}
          onChange={(e) => updateFormData({ email: e.target.value })}
          marginBottom='2rem'
          required
        />
        <Input
          type='text'
          width='20em'
          label='Insurance Provider'
          value={formData.insurance}
          onChange={(e) => updateFormData({ insurance: e.target.value })}
          marginBottom='2rem'
          required
        />
        <Input
          type='text'
          width='20em'
          label='ZIP Code'
          inputMode='numeric'
          value={formData.zip}
          onChange={(e) => updateFormData({ zip: e.target.value })}
          marginBottom='5rem'
          required
        />
        <Button>{`Send My Code!`}</Button>
      </StyledForm>
    </>
  );
};
