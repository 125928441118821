import { Flex, Image, BigText } from './component-library';
import { FirstForm, LandingPage, PositionInstructions, Results, SecondForm, SuccessPage, TestPage } from './screens';
import { ScoreProvider } from './context/scoreContext';
import { useNavigation } from './context/navigationContext';
import { useForm } from './context/formContext';
import { useLayoutEffect, useState } from 'react';
import oneLifeLogo from './assets/img/one-life-logo.svg';
//import any other logo for white label.

const App = () => {
  const [displayApp, setDisplayApp] = useState(true);
  const { currentPageIndex } = useNavigation();

  useLayoutEffect(() => {
    const checkScreenSizeAndDeviceType = () => {
      const query = window.matchMedia('(min-width: 1050px)');
      if (!query.matches) return setDisplayApp(false);
      if (query.matches) return setDisplayApp(true);
    };

    window.addEventListener('resize', () => checkScreenSizeAndDeviceType());
    checkScreenSizeAndDeviceType();
  }, []);

  return !displayApp ? (
    <ErrorMessage />
  ) : (
    <GlobalContainer>
      <MainContent>
        <ScoreProvider>{routes[currentPageIndex]}</ScoreProvider>
      </MainContent>
      <Footer page={currentPageIndex} />
    </GlobalContainer>
  );
};

const ErrorMessage = () => {
  return (
    <Flex width='100vw' height='100vh' centered>
      <BigText textAlign='center'>{`Your screen is too small to display this site. Try rotating your device.`}</BigText>
    </Flex>
  );
};

const MainContent = ({ children }) => (
  <Flex column centered flex='1'>
    {children}
  </Flex>
);

const GlobalContainer = ({ children }) => (
  <Flex
    column
    height='100vh'
    onClick={() => {
      if (document.fullscreenElement) return false;
      document.body.webkitRequestFullscreen();
      document.body.requestFullscreen();
    }}
  >
    {children}
  </Flex>
);

const Footer = ({ page }) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      padding='0 2rem'
      height='100px'
      width='100%'
      backgroundColor={page !== 3 ? '#02011E' : '#fff'}
    >
      <a
        href='https://ibis.vision'
        target='_blank'
        rel='noreferrer'
        style={{ textDecoration: 'none', color: '#fff', fontSize: '0.5rem' }}
      >{`POWERED BY IBISVISION`}</a>
      <OneLifeLogo />
    </Flex>
  );
};

const OneLifeLogo = () => {
  const { returnHome } = useNavigation();
  const { resetFormData } = useForm();
  return (
    <Image
      src={oneLifeLogo}
      alt='one life fitness'
      width='80px'
      style={{ zIndex: 50 }}
      onClick={() => {
        resetFormData();
        return returnHome();
      }}
    />
  );
};
//replace with any logo for white label.

const routes = {
  0: <LandingPage />,
  1: <FirstForm />,
  2: <PositionInstructions />,
  3: <TestPage />,
  4: <Results />,
  5: <SecondForm />,
  6: <SuccessPage />,
};

export default App;
