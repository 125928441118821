import { Flex, DOBEntry, Input, Button, BigText, Image, ConsentCheckbox } from '../component-library';
import { useNavigation } from '../context/navigationContext';
import { useForm } from '../context/formContext';
import FirstFormImg from '../assets/img/first-form-img.webp';
import { useState } from 'react';

export const FirstForm = () => {
  const { formData, updateFormData } = useForm();
  const [consentGiven, setConsentGiven] = useState(false);
  const { nextPage } = useNavigation();

  const fillForm = () => {
    const visionExpoPrefill = { name: 'Mel Berwick', dob: { month: '07', day: '04', year: '1983' } };
    setConsentGiven(true);
    updateFormData(visionExpoPrefill);
  };

  return (
    <>
      <Flex column width='100%' padding='0 10rem'>
        <BigText marginBottom='2rem'>{`Let's get to know you.`}</BigText>
        <Input label='Your Name' width='20em' marginBottom='2rem' value={formData.name} onChange={(e) => updateFormData({ name: e.target.value })} />
        <DOBEntry />
        <ConsentCheckbox checked={consentGiven} setChecked={setConsentGiven} />
        <Button marginTop='5rem' disabled={!consentGiven} onClick={() => nextPage()}>
          Next
        </Button>
      </Flex>
      <Image
        src={FirstFormImg}
        style={{ position: 'absolute', top: 0, right: 0 }}
        alt='woman checking phone in gym and smiling'
        onClick={() => fillForm()}
      />
    </>
  );
};
