import styled from '@emotion/styled';
import { shouldForwardProps } from '../shouldForwardProps';

const StyledFlex = ({ column, centered, ...props }) => {
  const { customPropsObj } = shouldForwardProps(props);

  return {
    display: 'flex',
    flexDirection: column && 'column',
    alignItems: centered && 'center',
    justifyContent: centered && 'center',
    ...customPropsObj,
  };
};

export const Flex = styled('div')(StyledFlex);
