import { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const nextPage = () => setCurrentPageIndex((index) => index + 1);
  const returnHome = () => setCurrentPageIndex(0);

  return <NavigationContext.Provider value={{ currentPageIndex, nextPage, returnHome }}>{children}</NavigationContext.Provider>;
};
