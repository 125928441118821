export const shouldForwardProps = (props) => {
  let validCustomProps = [];
  for (let prop in props) {
    if (typeof props[prop] === 'string' && prop !== 'children') {
      validCustomProps.push({ [`${prop}`]: props[prop] });
    }
  }

  const customPropsObj = Object.assign({}, ...validCustomProps);

  return { customPropsObj };
};
