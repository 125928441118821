import { Flex } from '../component-library';

export const OpticianEmail = ({ score }) => {
  return (
    <Flex column padding='2rem' color='#fff'>
      <h1>{`Dear Dr. Berwick,`}</h1>
      <p>
        Patient <strong>Melville</strong> has completed an online assessment using the IbisVision kiosk. These results are shown below:
      </p>
      <ul>
        <li>
          <strong>Name: </strong>Melville
        </li>
        <li>
          <strong>Age: </strong>39
        </li>
        <li>
          <strong>Insurance: </strong>XXXXXXXXX
        </li>
        <li>
          <strong>Email: </strong>mberwick@ibisvision.co.uk
        </li>
        <li>
          <strong>Zip code: </strong>NY 10001
        </li>
        <li>
          <strong>Score: </strong>
          {score} out of 20.
        </li>
      </ul>
      <p>
        You have received a new referral from a <strong>One Life Kiosk</strong>.
      </p>
      <p>Your patient will be in touch soon to book their eye test.</p>
    </Flex>
  );
};

export const PatientEmail = ({ score }) => {
  return (
    <Flex column padding='2rem' color='#fff'>
      <h1>{`Dear Melville,`}</h1>
      <p>
        Thanks for completing your online eye test at your <strong>Onelife Fitness</strong> gym in association with <strong>IbisVision</strong>.
      </p>
      <p>
        Your score was <strong>{score} out of 20.</strong>
      </p>
      <p>
        We have passed your details over to our provider, <strong>MyEyeDr.</strong>, and they will be in contact soon to book your appointment. Your
        unique referral code is <strong>IBISVISION2023</strong>. Remember to share this when booking.
      </p>
      <p>
        Having your vision corrected can massively improve your quality of life. Regular eye testing can detect serious eye conditions such as
        cataracts, glaucoma, and age-related macular degeneration, so, you should have your vision checked at least once every two years.
        Congratulations on taking the first step on this journey.
      </p>
      <p>We hope to see you again soon!</p>
    </Flex>
  );
};
