import styled from '@emotion/styled';
import { theme } from '../design-system';
import { shouldForwardProps } from '../shouldForwardProps';

const StyledButton = ({ variant, disabled, ...props }) => {
  const { customPropsObj } = shouldForwardProps(props);

  const getPropsByVariant = (variant) => {
    const variants = {
      filled: {
        main: {
          backgroundColor: theme.colors.primary,
          border: `1px solid ${theme.colors.primary}`,
          color: '#fff',
        },
        hover: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          border: `1px solid ${theme.colors.primary}`,
        },
        active: {
          backgroundColor: theme.colors.secondary,
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
          border: `1px solid ${theme.colors.secondary}`,
        },
        focus: {
          backgroundColor: theme.colors.primary,
          border: '1px solid #E71D72',
        },
        disabled: {
          backgroundColor: '#B5B5B5',
          border: '1px solid #b5b5b5',
          cursor: 'not-allowed',
        },
      },
    };

    return variant ? variants[variant] : variants['filled'];
  };

  const propsByVariant = getPropsByVariant(variant);

  return {
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s linear',
    padding: '1rem 1.5rem',
    fontSize: '1rem',
    fontFamily: 'Rockwell',
    width: '24em',
    ...(disabled ? propsByVariant.disabled : propsByVariant.main),
    '&:hover': !disabled && !props.backgroundColor && { ...propsByVariant.hover },
    '&:active': !disabled && !props.backgroundColor && { ...propsByVariant.active },
    '&:focus': !disabled && !props.backgroundColor && { ...propsByVariant.focus },
    borderColor: props.backgroundColor || null,
    ...customPropsObj,
  };
};

export const Button = styled('button')(StyledButton);
