import { Global, css } from '@emotion/react';

export const GlobalStyle = () => {
  const styles = css`
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
    import OpticianSans from '../fonts/OpticianSans/Webfont/Web-TT/Optiker-K.ttf';
    * {
      box-sizing: border-box;
    }
    html,
    body,
    root {
      width: 100vw;
      height: 100%;
      margin: 0;
      font-size: 16px;
      font-family: Rockwell;
      user-select: none;
      background-color: #02011E;
    }
  `;
  return <Global styles={styles} />;
};
