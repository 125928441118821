import styled from '@emotion/styled';
import { shouldForwardProps } from '../shouldForwardProps';
import { theme } from '../design-system';

const StyledText = ({ ...props }) => {
  const { customPropsObj } = shouldForwardProps(props);

  return {
    margin: 0,
    color: theme.colors.primary,
    fontSize: '2.5rem',
    ...customPropsObj,
  };
};

export const BigText = styled('p')(StyledText);
